<template>
  <transition name="slide">
    <div class="history">
      <h :isback="true">
        <span class="name">观看历史</span>
        <span
          class="details"
          @click="editShow"
          v-text="editShowFlag ? '取消' : '编辑'"
        />
      </h>
      <div class="main">
        <cube-scroll ref="scroll" :data="list" :options="options">
          <ul v-if="list.length" class="list">
            <li v-for="item in list" :key="item.name">
              <div class="title" v-text="item.name" />
              <div v-for="video in item.data" :key="video.id" class="content">
                <div v-show="editShowFlag" class="s">
                  <cube-checkbox
                    v-model="video.check"
                    shape="square"
                    :option="{ label: '', value: video.check }"
                    @input="checkInput"
                  />
                </div>
                <div class="l" @click="toPlay(video)">
                  <div class="cover">
                    <img
                      v-lazy="resetUrl(video.horizontalImage)"
                      class="image"
                    />
                    <span v-if="video.roleStatus == 2" class="type">VIP</span>
                    <!-- <span
                      v-if="video.videoType == 3"
                      class="type"
                      v-text="'已解锁'"
                    /> -->
                    <div class="desc">
                      <span v-if="video.videoType == 0 || video.videoType == 3">
                        <b
                          v-show="video.hour > 0"
                          v-text="
                            video.hour < 10
                              ? '0' + video.hour + ':'
                              : video.hour + ':'
                          "
                        />
                        <b
                          v-show="video.minute > 0"
                          v-text="
                            video.minute < 10
                              ? '0' + video.minute + ':'
                              : video.minute + ':'
                          "
                        />
                        <b v-show="video.minute <= 0" v-text="'00:'" />
                        <b
                          v-text="
                            video.second < 10
                              ? '0' + video.second
                              : video.second
                          "
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div class="r" @click="toPlay(video)">
                  <div class="text">
                    <div class="theme ellips" v-text="video.name" />
                    <div v-show="video.videoType != 0" class="num">
                      第{{ video.number }}集
                    </div>
                    <div class="process">
                      <img src="./img/tv.png" width="22" class="type" />
                      <!-- <img src="./img/phone.png" width="17" /> -->
                      <span
                        class="txt"
                        v-text="
                          video.planProcess > 0
                            ? '观看至' + video.planProcess + '%'
                            : '观看不足1%'
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div v-else class="not-data">
            <img src="./img/not-data.png" width="109" />
            <div class="txt">暂无数据</div>
          </div>
        </cube-scroll>
      </div>
      <div v-show="editShowFlag" class="btns">
        <div class="btn" v-text="all ? '取消全选' : '全选'" @click="allSel" />
        <div class="btn del" @click="del">删除</div>
      </div>
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import { getHistoryList, delHistory } from "@/api/user";
import { mapState } from "vuex";
export default {
  components: {
    h
  },
  data() {
    return {
      list: [],
      editShowFlag: false,
      checkeds: {},
      all: false,
      options: {
        click: true
      }
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token
    })
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getHistoryList().then(res => {
        if (!res) {
          return;
        }
        this.list = res.object.filter(item => {
          return item.data.length > 0;
        });
        this.list.forEach(item => {
          item.data.forEach(video => {
            video.check = false;
          });
        });
      });
    },
    editShow() {
      this.editShowFlag = !this.editShowFlag;
    },
    allSel() {
      if (this.list.length <= 0) {
        return;
      }
      this.list.forEach(item => {
        item.data.forEach(video => {
          video.check = !this.all;
        });
      });
      this.all = !this.all;
    },
    checkInput() {
      let ischeck = [];
      this.list.forEach(item => {
        item.data.forEach(video => {
          if (!video.check) {
            ischeck.push(video.id);
          }
        });
      });
      this.all = !ischeck.length;
      this.$forceUpdate();
    },
    del() {
      const ids = [];
      this.list.forEach(item => {
        item.data.forEach(video => {
          if (video.check) {
            ids.push(video.id);
          }
        });
      });
      const params = {
        ids: ids.join()
      };
      delHistory(params).then(res => {
        if (!res) {
          return;
        }
        this.getList();
        this.editShow();
      });
    },
    resetUrl(url) {
      if (!url) {
        return;
      }
      return url.replace("http://qiniu", "https://qiniu");
    },
    toPlay(item) {
      if (!this.token) {
        return "/login";
      }
      let url = "";
      if (item.videoType == 0 || item.videoType == 3) {
        url = `/video/${item.videoId}?type=${item.videoType}`;
      } else if (item.videoType == 1) {
        url = `/video/${item.categoryId}?type=${item.videoType}&number=${item.videoId}`;
      } else if (item.videoType == 2) {
        url =
          item.videoType.number <= 0
            ? `/search/more?id=${item.categoryId}`
            : `/video/${item.categoryId}?type=${item.videoType}`;
      }
      if (!item.videoType && item.isPay == 1) {
        url = `/video/${item.videoId}?type=3`;
      }
      this.$router.push(url);
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.history
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .main
    height calc(100% - 36px)
    margin 14px
    .list
      li
        padding-bottom 28px
      .title
        padding-bottom 10px
      .content
        display flex
        align-items center
        padding-bottom 12px
        .l
          flex 0 0 169px
          margin-right 9px
          .cover
            position relative
            font-size 0
            .image
              border-radius 4px
            .type
              position absolute
              top 5px
              right 6px
              width 28px
              height 14px
              line-height 14px
              background #BA9877
              border-radius 3px
              font-style normal
              font-size 10px
              color #fff
              text-align center
            .desc
              position absolute
              top 0
              left 0
              right 0
              bottom 0
              text-align right
              box-shadow 0px -70px 6px -50px rgba(0,0,0, .6) inset
              span
                position absolute
                bottom 3px
                right 5px
                font-size 10px
                color #fff
        .r
          display flex
          flex 1
          align-items center
          overflow hidden
          .text
            width 100%
          .theme
            font-size 13px
            color #383A40
          .num
            margin 6px 0
          .process
            color #6B6B6B
            .type
              margin-right 6px
            .type,.txt
              display inline-block
              vertical-align middle
        .s
          .cube-checkbox
            padding 0 6px
  .btns
    position absolute
    right 14px
    left 14px
    bottom 14px
    height 40px
    line-height 40px
    font-size 17px
    text-align center
    z-index 1
    .btn
      display inline-block
      width 50%
      background #f0f0f0
    .del
      background #2364cf
      color #fff
  .not-data
    text-align center
    margin-top 120px
    .txt
      margin-top 20px
      font-size 17px
      color #CECECE
</style>
