var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide"}},[_c('div',{staticClass:"history"},[_c('h',{attrs:{"isback":true}},[_c('span',{staticClass:"name"},[_vm._v("观看历史")]),_c('span',{staticClass:"details",domProps:{"textContent":_vm._s(_vm.editShowFlag ? '取消' : '编辑')},on:{"click":_vm.editShow}})]),_c('div',{staticClass:"main"},[_c('cube-scroll',{ref:"scroll",attrs:{"data":_vm.list,"options":_vm.options}},[(_vm.list.length)?_c('ul',{staticClass:"list"},_vm._l((_vm.list),function(item){return _c('li',{key:item.name},[_c('div',{staticClass:"title",domProps:{"textContent":_vm._s(item.name)}}),_vm._l((item.data),function(video){return _c('div',{key:video.id,staticClass:"content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editShowFlag),expression:"editShowFlag"}],staticClass:"s"},[_c('cube-checkbox',{attrs:{"shape":"square","option":{ label: '', value: video.check }},on:{"input":_vm.checkInput},model:{value:(video.check),callback:function ($$v) {_vm.$set(video, "check", $$v)},expression:"video.check"}})],1),_c('div',{staticClass:"l",on:{"click":function($event){return _vm.toPlay(video)}}},[_c('div',{staticClass:"cover"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.resetUrl(video.horizontalImage)),expression:"resetUrl(video.horizontalImage)"}],staticClass:"image"}),(video.roleStatus == 2)?_c('span',{staticClass:"type"},[_vm._v("VIP")]):_vm._e(),_c('div',{staticClass:"desc"},[(video.videoType == 0 || video.videoType == 3)?_c('span',[_c('b',{directives:[{name:"show",rawName:"v-show",value:(video.hour > 0),expression:"video.hour > 0"}],domProps:{"textContent":_vm._s(
                          video.hour < 10
                            ? '0' + video.hour + ':'
                            : video.hour + ':'
                        )}}),_c('b',{directives:[{name:"show",rawName:"v-show",value:(video.minute > 0),expression:"video.minute > 0"}],domProps:{"textContent":_vm._s(
                          video.minute < 10
                            ? '0' + video.minute + ':'
                            : video.minute + ':'
                        )}}),_c('b',{directives:[{name:"show",rawName:"v-show",value:(video.minute <= 0),expression:"video.minute <= 0"}],domProps:{"textContent":_vm._s('00:')}}),_c('b',{domProps:{"textContent":_vm._s(
                          video.second < 10
                            ? '0' + video.second
                            : video.second
                        )}})]):_vm._e()])])]),_c('div',{staticClass:"r",on:{"click":function($event){return _vm.toPlay(video)}}},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"theme ellips",domProps:{"textContent":_vm._s(video.name)}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(video.videoType != 0),expression:"video.videoType != 0"}],staticClass:"num"},[_vm._v(" 第"+_vm._s(video.number)+"集 ")]),_c('div',{staticClass:"process"},[_c('img',{staticClass:"type",attrs:{"src":require("./img/tv.png"),"width":"22"}}),_c('span',{staticClass:"txt",domProps:{"textContent":_vm._s(
                        video.planProcess > 0
                          ? '观看至' + video.planProcess + '%'
                          : '观看不足1%'
                      )}})])])])])})],2)}),0):_c('div',{staticClass:"not-data"},[_c('img',{attrs:{"src":require("./img/not-data.png"),"width":"109"}}),_c('div',{staticClass:"txt"},[_vm._v("暂无数据")])])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editShowFlag),expression:"editShowFlag"}],staticClass:"btns"},[_c('div',{staticClass:"btn",domProps:{"textContent":_vm._s(_vm.all ? '取消全选' : '全选')},on:{"click":_vm.allSel}}),_c('div',{staticClass:"btn del",on:{"click":_vm.del}},[_vm._v("删除")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }